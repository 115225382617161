import { ICategoriesResponse, ICategory, TCategoriesParams } from './Categories.domain';
import instanse from '@/common/service/api/Axios';
import { ApiUrls } from '@/common/domain/Endpoints.domain';

export const fetchCategoriesFeatured = (
    params?: TCategoriesParams
): Promise<ICategoriesResponse> => {
    return instanse
        .get<ICategoriesResponse>(ApiUrls.categories.root, { params })
        .then(({ data }) => data);
};

export const getCategoriesList = (): Promise<ICategoriesResponse> => {
    return fetchCategoriesFeatured();
};

const mapApiCategories = (categories: ICategoriesResponse): ICategory[] => {
    return categories.items.map((category) => {
        return {
            id: category.id,
            name: category.name,
            ord: category.ord,
            slug: category.slug,
            url: category.url,
            view: category.view,
        };
    });
};

export const fetchAppCategories = async (): Promise<ICategory[]> => {
    const response = await getCategoriesList();

    return mapApiCategories(response);
};
