import { createContext, memo, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { fetchAppDestinations } from '@/common/service/api/Destination/Destination';
import { IDestination } from '@/entities/Destination/domain/Destination.domain';
import { fetchAppCategories } from '@/common/service/api/Categories/Categories';
import { ICategory } from '@/common/service/api/Categories/Categories.domain';

import { TPartnersItem } from '@/common/service/api/Partners/Partners.domain';

type ProviderProps = {
    children: ReactNode | undefined;
};

type ContextProps = {
    destinations: IDestination[];
    categories: ICategory[];
    partners: TPartnersItem[];
};

const Context = createContext<ContextProps>({
    destinations: [],
    categories: [],
    partners: [],
});

const ContextProvider = ({ children }: ProviderProps) => {
    const [destinations, setDestinations] = useState<IDestination[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [partners] = useState<TPartnersItem[]>([]);

    useEffect(() => {
        const initDestinations = async () => {
            const apiDestinations = await fetchAppDestinations();
            setDestinations(apiDestinations);
        };

        const initCategories = async () => {
            const apiCategories = await fetchAppCategories();
            setCategories(
                apiCategories.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                })
            );
        };

        const mounted = () => {
            initDestinations();
            initCategories();
        };

        mounted();
    }, []);

    const contextProviderValues = useMemo(
        () => ({
            destinations,
            categories,
            partners,
        }),
        [destinations, categories, partners]
    );

    return <Context.Provider value={contextProviderValues}>{children}</Context.Provider>;
};

export const AppProvider = memo(ContextProvider);
export const useAppContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useAppContext must be used within AppContext');
    }

    return context;
};
