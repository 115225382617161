import { format } from 'date-fns';
import { SEARCH_FORMAT_DATE } from '../constants/timeConstants';
import { getDateByTimezone } from './dateUtils';

export const replaceDateInPath = (asPath: string, from: string, to: string) => {
    let newPath = asPath;

    const newFrom = `from=${format(getDateByTimezone(new Date(from)), SEARCH_FORMAT_DATE)}`;
    const newTo = `to=${format(getDateByTimezone(new Date(to)), SEARCH_FORMAT_DATE)}`;

    if (asPath.includes('from=')) {
        newPath = newPath.replace(/from=(\d{2}\/\d{2}\/\d{4})/, newFrom);
    } else {
        const sign = asPath.includes('?') ? '&' : '?';
        newPath = newPath.concat(sign, newFrom);
    }

    if (asPath.includes('to=')) {
        newPath = newPath.replace(/to=(\d{2}\/\d{2}\/\d{4})/, newTo);
    } else {
        newPath = newPath.concat('&', newTo);
    }

    return newPath;
};
