import { isProd } from './envs';

export const STRIPE_KEY = isProd
    ? 'pk_live_r3tqBmy05RnhVvIMWZha0OMO'
    : 'pk_test_RTVcovXMT39KtjNv8584ASyl';

export const GAC_COOKIE_NAME = isProd ? '_gac_UA-9061214-2' : '_gac_UA-9061214-6';

export const ITERABLE_KEY = isProd
    ? 'fb084b4971ca4a45996297a042520ebd'
    : '65617bc6915f49a9a8bae38aab965045';
