import { IDateRange } from '@/common/domain/Date.domain';
import { addDays, format, startOfToday, differenceInDays, parseISO } from 'date-fns';
import { SEARCH_FORMAT_DATE, SERVER_FORMAT_DATE } from '../constants/timeConstants';
import { TDate } from '../contexts/DateContext';

export const getDateByTimezone = (date: Date) => {
    return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};

export const dateQueryString = (date?: Date) => {
    const currentDate = typeof date === 'string' ? parseISO(date) : date || startOfToday();
    return format(currentDate, SEARCH_FORMAT_DATE);
};

export const getFormattedDateByQuery = (query?: string | string[]) => {
    try {
        if (query && typeof query === 'string') {
            const regexp = /^(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/(?:[0-9]{2})?[0-9]{2}$/;
            const regexpServer =
                /^(?:[0-9]{2})?[0-9]{2}-(1[0-2]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])$/;
            if (regexp.test(query)) {
                const arrDate = query.split('/');
                return `${arrDate[2]}-${arrDate[0]}-${arrDate[1]}`;
            }

            if (regexpServer.test(query)) {
                return query;
            }
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
};

export const getFormattedRangeDate = (date?: Date) => {
    const currentDate = typeof date === 'string' ? parseISO(date) : date || startOfToday();
    return format(currentDate, SERVER_FORMAT_DATE);
};

export const getRangeDateBySlug = (query: TDate) => {
    const dateStart = new Date(query.from);
    const dateEnd = new Date(query.to);

    return {
        dateStart: getDateByTimezone(dateStart),
        dateEnd: getDateByTimezone(dateEnd),
    };
};

export const getRangeDateByQuery = (from?: string | string[], to?: string | string[]) => {
    const fromDate = from && typeof from === 'string' ? new Date(from) : new Date();
    const toDate = to && typeof to === 'string' ? new Date(to) : addDays(fromDate, 6);

    return {
        from: format(getDateByTimezone(fromDate), SERVER_FORMAT_DATE),
        to: format(getDateByTimezone(toDate), SERVER_FORMAT_DATE),
    };
};

export const addQueryString = (queryString?: string) => (queryString ? `/?${queryString}` : '');

export const dateRangeQueryString = (startDate?: Date, endDate?: Date) => {
    const startDateString = dateQueryString(startDate);
    const endDateString = dateQueryString(endDate);
    return `?from=${startDateString}&to=${endDateString}`;
};

export const addSlashString = (value?: string) => {
    if (value && value.length) {
        return `/${value}`;
    }
    return '';
};

export const getAttractionsUrl = ({
    destinationSlug,
    categorySlug,
    partner,
    urlDate,
}: {
    destinationSlug: string;
    categorySlug?: string;
    partner?: string;
    urlDate?: IDateRange;
}) => {
    if (partner) {
        return `${partner}${dateRangeQueryString(
            urlDate?.dateStart,
            urlDate?.dateEnd ?? addDays(startOfToday(), 6)
        )}`.replace(/ /g, '');
    }

    return `/attractions/${destinationSlug}${addSlashString(categorySlug)}${dateRangeQueryString(
        urlDate?.dateStart,
        urlDate?.dateEnd ?? addDays(startOfToday(), 6)
    )}`.replace(/ /g, '');
};

export const getNewDateRange = (dateRange: IDateRange): IDateRange => {
    const today = startOfToday();

    if (
        differenceInDays(
            typeof dateRange.dateStart === 'string'
                ? parseISO(dateRange.dateStart)
                : dateRange.dateStart,
            today
        ) < 0
    ) {
        return {
            dateStart: today,
            dateEnd: addDays(today, 7),
        };
    }

    return {
        dateStart:
            typeof dateRange.dateStart === 'string'
                ? parseISO(dateRange.dateStart)
                : dateRange.dateStart,
        dateEnd:
            typeof dateRange.dateEnd === 'string' ? parseISO(dateRange.dateEnd) : dateRange.dateEnd,
    };
};
