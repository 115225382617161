import { ICartItem } from '@/entities/Cart/domain/Cart.domain';
import {
    createContext,
    memo,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

type AnalyticsProviderProps = PropsWithChildren<{ isLoading: boolean }>;

interface IAnalyticsHandlers {
    // Function to be executed for GA4 and Iterables.
    trackAddToCart?: (item: ICartItem) => void;
}

type ContextProps = AnalyticsProviderProps & IAnalyticsHandlers;

const AnalyticsContext = createContext<ContextProps | null>(null);

const AnalyticsContextProvider = ({ children }: AnalyticsProviderProps) => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const contextMounted = () => {
            setLoading(false);
        };

        contextMounted();
    }, []);

    const providerValues = useMemo(
        () => ({
            isLoading,
        }),
        [isLoading]
    );

    return <AnalyticsContext.Provider value={providerValues}>{children}</AnalyticsContext.Provider>;
};

export const AnalyticsProvider = memo(AnalyticsContextProvider);

export const useAnalytics = () => {
    const context = useContext(AnalyticsContext);

    if (!context) {
        throw new Error('useAnalytics must be used within a AnalyticsProvider');
    }

    return context;
};
