export enum UrlsStaging {
    api = 'https://api.tripshock.com/dev_v1',
    content = 'https://api.tripshock.com/content-staging',
    redirects = 'https://staging-redirects.tripshock.com',
    affiliates = 'https://api.tripshock.com/affiliates-staging',
    crypto = 'https://api.tripshock.com/crypto-staging',
    image = 'https://staging-images.tripshock.com',
    apiImage = 'https://api.tripshock.com/image-staging',
    partners = 'https://partners.tripshock.com',
    talkToUs = 'https://y0nncpqj0h.execute-api.us-east-1.amazonaws.com/staging/',
    iterable = 'https://api.iterable.com/api/',
}

export enum UrlsProduction {
    api = 'https://api.tripshock.com/v1',
    content = 'https://api.tripshock.com/content',
    redirects = 'https://redirects.tripshock.com',
    affiliates = 'https://api.tripshock.com/affiliates',
    crypto = 'https://api.tripshock.com/crypto',
    image = 'https://images.tripshock.com',
    apiImage = 'https://api.tripshock.com/image',
    partners = 'https://partners.tripshock.com',
    talkToUs = 'https://88fjt7qdz8.execute-api.us-east-1.amazonaws.com/prod/',
    iterable = 'https://api.iterable.com/api/',
}
