import instance, { apiImageInstance } from '@/common/service/api/Axios';
import { ApiUrls } from '@/common/domain/Endpoints.domain';
import {
    IActivityDatelessResponse,
    TActivityParams,
    TImageById,
    TImageExcerpt,
    TImageItem,
} from './Activity.domain';

export const fetchActivityDateless = (
    destination_id: string,
    category_id?: string | number
): Promise<IActivityDatelessResponse> => {
    return instance
        .get<IActivityDatelessResponse>(ApiUrls.activity.dateless, {
            params: {
                destination_id,
                category_id,
            },
        })
        .then(({ data }) => data);
};

export const fetchActivity = (params: TActivityParams): Promise<IActivityDatelessResponse> => {
    return instance
        .get<IActivityDatelessResponse>(ApiUrls.activity.root, { params })
        .then(({ data }) => data);
};

export const fetchUserImagesInSearch = (
    activity_ids: string
): Promise<TImageExcerpt> | TImageExcerpt => {
    if (!activity_ids) return {} as TImageExcerpt;

    return apiImageInstance
        .get<TImageExcerpt>(ApiUrls.photo.excerpt, { params: { activity_ids: activity_ids } })
        .then(({ data }) => {
            if (data && typeof data === 'object') {
                return data;
            }
            return {};
        })
        .catch((err) => {
            console.error(err);
            return {} as TImageExcerpt;
        });
};

export const fetchUserImagesById = (id: string): Promise<TImageItem[]> => {
    return apiImageInstance
        .get<TImageById>(ApiUrls.photo.root, { params: { activity_id: id } })
        .then(({ data }) => {
            if (data && data?.items?.length) {
                return data.items;
            }
            return [] as TImageItem[];
        })
        .catch((err) => {
            console.error(err);
            return [] as TImageItem[];
        });
};

export const fetchActivityDatelessLandmarks = (
    landmark_id: string
): Promise<IActivityDatelessResponse> => {
    return instance
        .get<IActivityDatelessResponse>(ApiUrls.activity.dateless, {
            params: {
                landmark_id,
            },
        })
        .then(({ data }) => data);
};
