import { TDataObject, TEditMode } from '@/common/service/api/Availability/Availability.domain';
import {
    applyPromocode,
    getPromocodeName,
    removePromocode,
} from '@/common/service/api/Promocodes/Promocode';
import {
    CookiesTypes,
    LocalStorageTypes,
    SessionStorageType,
    getLS,
    setLS,
} from '@/common/service/storage';
import { getCS, saveTokenCart } from '@/common/service/storage/CookieStorage';
import {
    loadCookiePromocode,
    saveCookiePromocode,
} from '@/common/service/storage/CookieStorage/CookieStorage';
import { setSessionStorage } from '@/common/service/storage/SessionStorage/SessionStorage';
import {
    delStorageCartActivity,
    getStorageCartActivities,
    removeStorageActivityItem,
} from '@/entities/Cart/app/storage/cartStorage';
import {
    CartData,
    CartGetResponse,
    ICartItem,
    ICartTotals,
    TCart,
    TRunToast,
} from '@/entities/Cart/domain/Cart.domain';
import { deleteCartItem } from '@/entities/Cart/service/deleteCartItem';
import { extendCart } from '@/entities/Cart/service/extendCart';
import { getCart } from '@/entities/Cart/service/getCart';
import { trackBeginCheckout, trackRemoveFromCart, trackViewCart } from '@/shared/Analytics/GA4';
import { useRouter } from 'next/router';
import {
    PropsWithChildren,
    createContext,
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ulid } from 'ulid';
import { AnalyticsInstance } from '@/common/service/analytics';
import { useDateContext } from '@/common/app/contexts/DateContext';
import { promoFailed } from '@/common/app/utils/promoFailed';
import { IApiCartItem, TPromoStatus } from '../domain/Cart.domain';
import { TGuestObject } from '@/common/app/contexts/ActivityContext';
import { trackCheckout, trackPromoCodeRequest } from '@/common/app/utils/iterable';
import { convCartItemsToPurchaseItems } from '@/common/app/utils/iterable/creators';
import {
    TToastData,
    TToastOptions,
    TToastsName,
} from '@/common/ui/modals/Notification/Notification.domain';
import { getToastData } from '@/common/ui/modals/Notification/creators';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { TConfig } from '@/common/service/api/Config/Config.domain';
import { fetchConfig } from '@/entities/Checkout/service/fetchConfig';
import {
    getAnalyticsDataForTrackRemoveCart,
    getAnalyticsDataForTrackViewCart,
} from '@/shared/Analytics/GA4/helpers';

const ONE_MIN = 60;
const FIVE_MINS = 300;
const ITERABLE_STEP_NUMBER = 1;

type CartProviderProps = PropsWithChildren<TCart>;

type TAnalyticsCallback = (data: CartData) => void;

interface ICartHandlers {
    addItem?: (item: ICartItem) => void;
    removeItem: (item: IApiCartItem) => void;
    editTicket: (item: IApiCartItem) => void;
    clearCart?: () => void;
    promoValidation: (promo: string) => void;
    promoRemove: () => void;
    getItem?: (sessionId: string) => void;
    submitContinue: () => void;
    initCart: (options?: { isCartPage?: boolean; initSessionId?: string }) => void;
    updateUserId: (newId: string | null) => void;
    updateTimer: (leftTime: number) => void;
    setExpired: () => void;
    addAnalyticsCallback?: (cb: TAnalyticsCallback) => void;
    runToast?: TRunToast;
    clearToastData?: () => void;
}

type ContextProps = TCart & ICartHandlers;

const DEFAULT_STORE: ContextProps = {
    // TODO possible we don't need items at all
    isLoading: true,
    expireTime: 0,
    items: [],
    cartData: {} as CartData,
    activities: [],
    totals: {
        id: '',
        total: 0,
        subTotal: 0,
        subTotalSubunit: 0,
        surcharges: 0,
        surchargesSubunit: 0,
        totalSubunits: 0,
        tripsCount: 0,
        customTotal: 0,
        discount: [],
    },
    sessionId: '',
    userId: '',
    isOutdated: false,
    isLoaded: false,
    isError: false,
    isExpired: false,
    expiredItems: [],
    promoStatus: {
        isLoading: false,
        isError: false,
        message: '',
    },
    submitContinue: () => {
        return;
    },
    initCart: () => {
        return;
    },
    removeItem: () => {
        return;
    },
    editTicket: () => {
        return;
    },
    updateUserId: () => {
        return;
    },
    promoValidation: () => {
        return;
    },
    promoRemove: () => {
        return;
    },
    updateTimer: () => {
        return;
    },
    setExpired: () => {
        return;
    },
};

const CartContext = createContext<ContextProps>(DEFAULT_STORE);

const CartContextProvider = ({ children, ...props }: CartProviderProps) => {
    const [isLoading, setLoading] = useState(DEFAULT_STORE.isLoading);
    const [items, setItems] = useState<ICartItem[]>(props?.items ?? DEFAULT_STORE.items);
    const [promocode, setPromocode] = useState(props?.promocode ?? DEFAULT_STORE.promocode);
    const [sessionId, setSessionId] = useState(DEFAULT_STORE.sessionId || '');
    const [userId, setUserId] = useState(DEFAULT_STORE.userId);
    const [cartData, setCartData] = useState(DEFAULT_STORE.cartData);
    const [promoStatus, setPromoStatus] = useState<TPromoStatus>(DEFAULT_STORE.promoStatus);
    const [isExpired, setIsExpired] = useState<boolean>(DEFAULT_STORE.isExpired);
    const [expireTime, setExpireTime] = useState<number>(0);
    const [analyticsCallback, setAnalyticsCallback] = useState<TAnalyticsCallback>();
    const [expiredItems, setExpiredItems] = useState<IApiCartItem[]>([]);
    const [toastData, setToastData] = useState<TToastData>();
    const [activities, setActivities] = useState<IActivity[]>(
        props?.activities ?? DEFAULT_STORE.activities
    );
    const [config, setConfig] = useState<TConfig>();

    const [totals, setTotals] = useState<ICartTotals>(props?.totals ?? DEFAULT_STORE.totals);

    const { push } = useRouter();
    const { changeDate } = useDateContext();

    const addItem = useCallback(
        (item: ICartItem) => {
            const newItems = [...items, item];
            setItems(newItems);
            setTotals({
                ...totals,
                tripsCount: newItems.length,
            });
        },
        [items, totals]
    );

    const updateTimer = useCallback(
        async (leftTime: number) => {
            const res = await extendCart(sessionId);

            if (res.success) {
                const nextTimeLeft = leftTime + FIVE_MINS;
                const lifeTimeSeconds = cartData.lifetime * ONE_MIN;

                const timeLeft = nextTimeLeft > lifeTimeSeconds ? lifeTimeSeconds : nextTimeLeft;

                setCartData({
                    ...cartData,
                    time_left: timeLeft,
                });

                setExpireTime(new Date().getTime() + timeLeft * 1000);
            } else {
                const errors = Array.isArray(res.errors)
                    ? res.errors
                    : ['Failed to extend time cart'];
                throw new Error(errors.join(', '));
            }
        },
        [cartData, sessionId]
    );

    const initConfig = useCallback(async () => {
        const newConfig = await fetchConfig();
        setConfig(newConfig);
    }, []);

    /* eslint-disable sonarjs/cognitive-complexity */
    const initCart = useCallback<
        (options?: { initSessionId?: string; isCartPage?: boolean }) => void
    >(
        async (options) => {
            setLoading(true);
            setExpiredItems([]);

            const newSessionId = options?.initSessionId ? options.initSessionId : sessionId;

            setActivities(getStorageCartActivities());

            if (newSessionId) {
                if (!config) {
                    initConfig();
                }

                const newCartData = await getCart(newSessionId);
                let cartDataPromo: CartGetResponse | undefined;

                const cookiePromo = loadCookiePromocode();
                const cartPromo = newCartData.data?.discount?.promo?.code?.toLowerCase();

                if (cookiePromo && !cartPromo) {
                    await promoValidation(cookiePromo, true);
                    cartDataPromo = await getCart(newSessionId);
                } else if (cartPromo && (cartPromo !== promocode || cartPromo !== cookiePromo)) {
                    setPromocode(cartPromo);

                    if (cookiePromo !== cartPromo) {
                        saveCookiePromocode(cartPromo);
                    }
                }

                const success = cartDataPromo?.success
                    ? cartDataPromo.success
                    : newCartData.success;

                const data = cartDataPromo?.success ? cartDataPromo.data : newCartData.data;

                if (success && data?.cart_id) {
                    setCartData(data);

                    // current time in ms + time left in ms
                    setExpireTime(new Date().getTime() + data.time_left * 1000);
                    const items = data.cart_items;

                    if (options?.isCartPage) {
                        try {
                            AnalyticsInstance.product.viewCart(
                                AnalyticsInstance.creators.createProductsInCartFromCartItems({
                                    categoryName: '',
                                })(items),
                                items
                            );
                        } catch (err) {
                            console.log('GA3 view cart error!', err);
                        }

                        if (analyticsCallback) {
                            analyticsCallback(data);

                            try {
                                const item = items[0];

                                const analyticsPayload = {
                                    name: item.product_name,
                                    id: item.product_id,
                                    price: +item.subtotal,
                                    brand: item.partner_title,
                                    category:
                                        item.product_city_info.name +
                                        '|' +
                                        item.product_category_info.name,
                                    variant: item.tickets[0].name,
                                    quantity: +item.tickets[0].booked,
                                };

                                AnalyticsInstance.product.addToCart(analyticsPayload);
                            } catch (err) {
                                console.log(err);
                            }

                            setAnalyticsCallback(undefined);
                        }
                        // data for cart & reduce old data
                        trackViewCart(getAnalyticsDataForTrackViewCart(data));
                    }
                } else {
                    setCartData(DEFAULT_STORE.cartData);
                    setActivities([]);
                    delStorageCartActivity();
                    setLS('ga4Data', {});
                    setExpireTime(0);
                }
            }
            setIsExpired(false);
            setLoading(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sessionId, promocode, config, analyticsCallback]
    );

    const removeItem = useCallback(
        async (item: IApiCartItem) => {
            if (item && sessionId) {
                const removeReq = await deleteCartItem({
                    session_id: sessionId,
                    cart_item_id: item.id,
                });

                if (removeReq.success) {
                    removeStorageActivityItem(item.product_id);

                    initCart();

                    trackRemoveFromCart(getAnalyticsDataForTrackRemoveCart(item));
                }
            }
        },
        [sessionId, initCart]
    );

    const editTicket = useCallback(
        async (item: IApiCartItem) => {
            await removeItem(item);

            const payload: TEditMode = {
                item_id: item.id,
                date: item.arrival_date,
                rate_id: item.rate_id || 0,
                schedule_id: item.schedule.id,
                tickets: item.tickets.reduce((result, { id, booked }) => {
                    result[id] = booked;
                    return result;
                }, {} as TDataObject),
                additional_options: item.additional_options.reduce((result, { id, value }) => {
                    result[id] = value.id;
                    return result;
                }, {} as TDataObject),
                guests: item.tickets.reduce((result, { id, guests }) => {
                    result[+id] = guests;
                    return result;
                }, {} as TGuestObject),
            };

            setSessionStorage(SessionStorageType.EDIT_MODE, payload);
            changeDate(item.arrival_date);

            push(item.product_url);
        },
        [push, changeDate, removeItem]
    );

    const clearCart = useCallback(() => {
        setItems([]);
        setActivities([]);
        delStorageCartActivity();
        setTotals(DEFAULT_STORE.totals);
        initCart();
    }, [initCart]);

    const getItem = useCallback(
        (sessionId: string) => {
            if (sessionId != '') {
                return items;
            }
            return '';
        },
        [items]
    );

    const updateUserId = useCallback((newId: string | null) => {
        if (newId) {
            setUserId(newId);
        }
    }, []);

    const submitContinue = useCallback(() => {
        push('/checkout/');

        trackBeginCheckout(getAnalyticsDataForTrackViewCart(cartData));

        const purchaseItems = convCartItemsToPurchaseItems(cartData.cart_items);

        trackCheckout(ITERABLE_STEP_NUMBER, purchaseItems);

        try {
            AnalyticsInstance.checkout.step({
                products: AnalyticsInstance.creators.createProductsInCartFromCartItems({
                    categoryName: '',
                })(cartData.cart_items),
                step: 1,
                option: 'Contact Info',
            });
        } catch (e) {
            console.log('GA3 checkout step event error!', e);
        }
    }, [cartData, push]);

    const runToast = useCallback(
        (type: TToastsName, options?: TToastOptions) => {
            if (!toastData) {
                const data = getToastData(type, options);
                setToastData(data);
            }
        },
        [toastData]
    );

    const promoValidation = useCallback(
        async (promocodeInput: string, offInitCart?: boolean) => {
            if (!promocodeInput || !sessionId) {
                promoFailed(setPromoStatus, 'Please enter your promo code.');
                return;
            }

            setPromoStatus({
                isLoading: true,
                isError: false,
                message: 'Loading new promo code.',
            });

            const promo = promocodeInput.toLowerCase();

            const res = await applyPromocode({
                session_id: sessionId,
                code: promo,
            });

            if (res.success) {
                const { name } = await getPromocodeName(promo);

                if (name) {
                    trackPromoCodeRequest({
                        success: true,
                        name: name,
                        code: promo,
                    });

                    saveCookiePromocode(promo);

                    setPromocode(promo);
                    setPromoStatus({
                        isLoading: false,
                        isError: false,
                        message: '',
                    });
                    if (!offInitCart) {
                        initCart();
                    }
                    runToast('promocode', { promoName: name });
                } else {
                    setPromocode('');
                    promoFailed(setPromoStatus, 'Wrong promo code name.', promo);
                }
            } else {
                promoFailed(setPromoStatus, res?.errors, promo);
            }
        },
        [sessionId, initCart, runToast]
    );

    const promoRemove = useCallback(async () => {
        setPromoStatus({
            isLoading: true,
            isError: false,
            message: 'Removing promo code, please wait.',
        });

        const res = await removePromocode(sessionId);

        if (res.success) {
            setPromocode('');
            setPromoStatus({
                isLoading: false,
                isError: false,
                message: '',
            });
            saveCookiePromocode('');
            initCart();
        } else {
            promoFailed(setPromoStatus, 'Failed to remove discount from cart');
        }
    }, [sessionId, initCart]);

    const setExpired = useCallback(() => {
        if (!isExpired) {
            setIsExpired(true);
            setExpireTime(0);
            setExpiredItems(cartData.cart_items.filter((item) => item));
            runToast('expired_cart');
        }

        setCartData(DEFAULT_STORE.cartData);
        setExpireTime(0);
        setActivities([]);
        delStorageCartActivity();
    }, [cartData.cart_items, isExpired, runToast]);

    const clearToastData = useCallback(() => {
        setToastData(undefined);
    }, []);

    const addAnalyticsCallback = useCallback((cb: TAnalyticsCallback) => {
        setAnalyticsCallback(() => cb);
    }, []);

    useEffect(() => {
        const sessionField = getCS(CookiesTypes.CS_TOKEN_CART);

        const newSessionId = sessionField
            ? sessionField.toString()
            : ulid()
                  .split('')
                  .sort(() => 0.5 - Math.random())
                  .join('');

        if (!sessionField) {
            saveTokenCart(newSessionId);
        }

        const newUserId = getLS(LocalStorageTypes.LS_USER_ID) ?? '';

        setSessionId(newSessionId);
        setUserId(newUserId);

        initCart({ initSessionId: newSessionId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contextProviderValue = useMemo(
        () => ({
            isLoading,
            items,
            cartData,
            activities,
            totals,
            sessionId,
            userId,
            promoStatus,
            promocode,
            isExpired,
            expiredItems,
            toastData,
            expireTime,
            config,
            runToast,
            clearToastData,
            setCartData,
            addItem,
            removeItem,
            clearCart,
            promoValidation,
            promoRemove,
            getItem,
            submitContinue,
            initCart,
            editTicket,
            updateUserId,
            updateTimer,
            setExpired,
            addAnalyticsCallback,
        }),

        [
            isLoading,
            items,
            cartData,
            activities,
            totals,
            sessionId,
            userId,
            promoStatus,
            promocode,
            isExpired,
            expiredItems,
            toastData,
            expireTime,
            config,
            runToast,
            clearToastData,
            addItem,
            removeItem,
            clearCart,
            promoValidation,
            promoRemove,
            getItem,
            submitContinue,
            initCart,
            editTicket,
            updateUserId,
            updateTimer,
            setExpired,
            addAnalyticsCallback,
        ]
    );

    return <CartContext.Provider value={contextProviderValue}>{children}</CartContext.Provider>;
};

export const CartProvider = memo(CartContextProvider);

export const useCartContext = () => {
    const context = useContext(CartContext);

    if (!context) {
        throw new Error('Context must be used within a ContextProvider');
    }

    return context;
};
