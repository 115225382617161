import {
    createContext,
    memo,
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';

import { trackPurchase, userUpdate } from '@/common/app/utils/iterable';
import { LocalStorageTypes, setLS } from '@/common/service/storage';
import { useCartContext } from '@/entities/Cart/app/CartContext';
import { removePromocode } from '@/entities/Cart/service/removePromocode';
import {
    IDiscount,
    IOrderDetail,
    IPayData,
    IUserInfo,
    TAccessKey,
    TCheckout,
    TOrderArgs,
} from '../../../entities/Checkout/domain/Checkout.domain';
import { fetchByAccessKey } from '../../../entities/Checkout/service/fetchByAccessKey';
import {
    DEFAULT_CONTACT_FORM,
    TContactForm,
    useUserInfo,
} from '../../../entities/Checkout/app/hooks/useUserInfo';
import { AnalyticsInstance } from '@/common/service/analytics';
import { trackPurchaseGA4 } from '@/shared/Analytics/GA4';
import { convCartItemsToPurchaseItems } from '../utils/iterable/creators';
import { isEmpty } from '../utils/objectUtils';
import { getAnalyticsDataForPurchase } from '@/shared/Analytics/GA4/helpers';

interface ICheckoutHandlers {
    saveOrder?: (payload: TOrderArgs) => void;
    saveOrderData?: (data: IPayData) => void;
    saveOrderDetail?: (detail: IOrderDetail) => void;
    fetchByAccessKey?: (accessKey: TAccessKey) => void;
    saveUserInfo?: (userInfo: IUserInfo) => void;
    onSuccessfullyPayment: (accessKey: string) => void;
    clearCheckout: () => void;
}

type ContextProps = TCheckout & ICheckoutHandlers;
type CheckoutProviderProps = PropsWithChildren<TCheckout>;

export const DEFAULT_CHECKOUT: ContextProps = {
    contactForm: DEFAULT_CONTACT_FORM,
    order: {
        data: {
            accesskey: '',
            format_id: '',
            id: '',
        },
        detail: {
            id: '',
            format_id: '',
            user_id: '',
            is_direct: '',
            is_office: '',
            ts_agent: '',
            accesskey: '',
            made: '',
            state: '',
            was_refunded: '',
            refund_amount: '',
            has_different_days: false,
            customer_last_name: '',
            customer_first_name: '',
            customer_email: '',
            customer_phone: '',
            customer_address: '',
            customer_city: '',
            customer_zip: '',
            customer_state_id: '',
            customer_state_name: '',
            gross_total: 0,
            net_total: 0,
            comission: 0,
            affiliate_comission: 0,
            ts_surcharges: 0,
            transaction_info: {
                payment_type: '',
                payment_number: '',
            },
            discount: {} as IDiscount,
            cart_items: {},
            refund_amount_subunits: 0,
            gross_total_subunits: 0,
            net_total_subunits: 0,
            comission_subunits: 0,
            affiliate_comission_subunits: 0,
            ts_surcharges_subunits: 0,
            surcharges: 0,
        },
    },
    isLoading: false,
    onSuccessfullyPayment: () => {
        return;
    },
    clearCheckout: () => {
        return;
    },
};

const CheckoutContext = createContext<ContextProps>(DEFAULT_CHECKOUT);

const CheckoutContextProvider = ({ children, ...props }: CheckoutProviderProps) => {
    const contactForm: TContactForm = useUserInfo();
    const [order, setOrder] = useState<TOrderArgs>(props?.order ?? DEFAULT_CHECKOUT.order);
    const [orderData, setOrderData] = useState(props?.order?.data ?? DEFAULT_CHECKOUT.order.data);
    const [orderDetail, setOrderDetail] = useState(
        props?.order?.detail ?? DEFAULT_CHECKOUT.order.detail
    );

    const [accessKey, setAccessKey] = useState<TAccessKey>();
    const [isLoading, setIsLoading] = useState(DEFAULT_CHECKOUT.isLoading);
    const { sessionId: cartSessionID, cartData, updateUserId, clearCart } = useCartContext();

    const saveOrder = useCallback((order: TOrderArgs) => {
        if (!order) {
            setOrder(order);
        }
    }, []);

    const saveOrderData = useCallback(
        (data: IPayData) => {
            if (isEmpty(orderData)) {
                setOrderData(data);
            }
        },
        [orderData]
    );

    const saveOrderDetail = useCallback(
        (detail: IOrderDetail) => {
            if (isEmpty(orderDetail)) {
                setOrderDetail(detail);
            }
        },
        [orderDetail]
    );

    const saveAccessKey = useCallback(
        (key: TAccessKey) => {
            if (!accessKey) {
                setAccessKey(key);
            }
        },
        [accessKey]
    );

    const saveUserInfo = useCallback(
        (newUserInfo: IUserInfo) => {
            const { email, firstName, lastName, phone } = newUserInfo;
            setLS(LocalStorageTypes.LS_CONTACT_INFO, newUserInfo);

            updateUserId(newUserInfo.userId);

            if (email) {
                userUpdate(email, {
                    firstName,
                    lastName: lastName,
                    fullName: [firstName, lastName].filter(Boolean).join(' '),
                    phoneNumber: phone,
                });
            }
        },
        [updateUserId]
    );

    const clearCheckout = useCallback(() => {
        setOrder(DEFAULT_CHECKOUT.order);
        setAccessKey(undefined);
        setOrderData(DEFAULT_CHECKOUT.order.data);
        setOrderDetail(DEFAULT_CHECKOUT.order.detail);
    }, []);

    const onSuccessfullyPayment = useCallback(
        async (resAccessKey: string) => {
            if (isLoading) {
                return;
            }
            setIsLoading(true);

            try {
                const orderResp = await fetchByAccessKey(resAccessKey);

                if (!orderResp.success) {
                    const errors = Array.isArray(orderResp.errors)
                        ? orderResp.errors
                        : ['Wrong access key'];
                    throw new Error(errors.join(', '));
                }

                setOrder({ ...order, detail: orderResp.data });
                setIsLoading(false);

                try {
                    const orderItems = Object.values(orderResp.data.cart_items);

                    AnalyticsInstance.wrapAvoidError(() =>
                        AnalyticsInstance.checkout.transactionComplete({
                            products: AnalyticsInstance.creators.createProductsInCartFromCartItems({
                                categoryName: '',
                            })(orderItems),
                            purchase: AnalyticsInstance.creators.createPurchase(orderResp.data),
                        })
                    );
                } catch (err) {
                    console.log('GA3 track purchase error!', err);
                }

                trackPurchaseGA4(getAnalyticsDataForPurchase(orderResp.data));

                trackPurchase(
                    orderResp.data.gross_total,
                    {
                        code: orderResp.data?.discount?.promo?.code || '',
                        name: orderResp.data?.discount?.promo?.name || '',
                    },
                    convCartItemsToPurchaseItems(cartData.cart_items)
                );

                if (orderResp.data.discount?.promo?.name) {
                    await removePromocode({
                        session_id: cartSessionID || '',
                    });
                }

                clearCart?.();
            } catch (error) {
                console.error(error);
                setIsLoading(false);
                if (error instanceof Error) {
                    throw new Error(error.message);
                }
            }
        },
        [cartSessionID, isLoading, order, cartData, clearCart]
    );

    const contextProviderValue = useMemo(
        () => ({
            contactForm,
            order,
            accessKey,
            isLoading,
            saveAccessKey,
            saveOrder,
            clearCheckout,
            saveOrderData,
            saveOrderDetail,
            saveUserInfo,
            onSuccessfullyPayment,
        }),
        [
            contactForm,
            order,
            accessKey,
            isLoading,
            saveAccessKey,
            saveOrder,
            clearCheckout,
            saveOrderData,
            saveOrderDetail,
            saveUserInfo,
            onSuccessfullyPayment,
        ]
    );
    return (
        <CheckoutContext.Provider value={contextProviderValue}>{children}</CheckoutContext.Provider>
    );
};

export const CheckoutProvider = memo(CheckoutContextProvider);

export const useCheckoutContext = () => {
    const context = useContext(CheckoutContext);

    if (!context) {
        throw new Error('Context must be used within a ContextProvider');
    }

    return context;
};
