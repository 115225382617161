import { getCS } from '../../../service/storage';
import {
    sendLiveChatIterable,
    sendUserUpdate,
    trackIterable,
    trackPurchaseIterable,
    trackUpadteCartIterable,
} from './requests';
import { IItem, IPurchaseItem } from './domain';
import { createWishListObject } from './creators';

enum EVENT {
    attractions = 'attractions',
    productDetail = 'productDetail',
    wishListAdded = 'wishListAdded',
    wishListRemoved = 'wishListRemoved',
    checkout = 'checkout',
    pageView = 'pageView',
    promoCodeRequest = 'promoCodeRequest',
    subscribed = 'subscribed',
}

const getWpage = () =>
    (typeof window !== 'undefined' && window.location.pathname + window.location.search) || '';

export const trackUpdateCart = async (items: IPurchaseItem[]) => {
    return trackUpadteCartIterable(items);
};

export const trackAttractions = async (payload: {
    category: { id: number; slug: string; name: string } | null;
    destination: { id: number; slug: string; name: string };
    arrivalDate: number;
    departureDate: number;
}) => {
    return trackIterable(
        EVENT.attractions,
        Object.assign({}, payload, {
            arrival_date: new Date(payload.arrivalDate),
            departure_date: new Date(payload.departureDate),
        })
    );
};

export const trackProductDetail = async (payload: {
    product_id: number;
    brand: string;
    category: { id: number; slug: string; name: string };
    destination: { id: number; slug: string; name: string };
    image: string;
    name: string;
    price: number;
    price_strike_out: number | null;
}) => {
    return trackIterable(EVENT.productDetail, Object.assign({}, payload, { path: getWpage() }));
};

export const trackWishListAdded = async (payload: { id: number | string; slug: string }) => {
    return trackIterable(EVENT.wishListAdded, createWishListObject(payload));
};

export const trackWishListRemoved = async (payload: { id: number | string; slug: string }) => {
    return trackIterable(EVENT.wishListRemoved, createWishListObject(payload));
};

export const trackPurchase = async (
    grossTotal: number,
    promoDetails: { name: string; code: string },
    items: Array<IPurchaseItem>
) => {
    const campaignId = parseInt(`${getCS('iterableEmailCampaignId')}`, 10) || 0;
    const templateId = parseInt(`${getCS('iterableTemplateId')}`, 10) || 0;

    const dataReq = {
        total: grossTotal,
        items,
        promoCode: promoDetails.code.toLowerCase(),
        promoName: promoDetails.name,
        ...(campaignId && { campaignId }),
        ...(templateId && { templateId }),
    };

    return trackPurchaseIterable(dataReq);
};

export const trackCheckout = async (stage: 1 | 2, products: Array<IItem>) => {
    return trackIterable(EVENT.checkout, { stage, products });
};

export const trackPageView = async (payload?: { path?: string; title?: string }) => {
    const title = window.document?.title;
    const path = window.location?.pathname;

    return trackIterable(EVENT.pageView, {
        path: payload?.path || path,
        title: payload?.title || title,
    });
};

export const trackPromoCodeRequest = async (payload: {
    success: boolean;
    name: string;
    code: string;
}) => {
    return trackIterable(
        EVENT.promoCodeRequest,
        Object.assign({}, payload, {
            code: payload.code.toLowerCase(),
            path: getWpage(),
        })
    );
};

export const trackSubscribed = (payload: { email: string }) => {
    return trackIterable(
        EVENT.subscribed,
        Object.assign({}, payload, {
            path: getWpage(),
        }),
        payload.email
    );
};

export const userUpdate = async (
    email: string,
    payload: {
        firstName: string;
        lastName: string;
        fullName: string;
        picture?: string;
        phoneNumber: string;
    }
) => {
    return sendUserUpdate({ email, dataFields: payload });
};

export const trackLiveChat = (attributesEmail: string, firstName: string) => {
    return sendLiveChatIterable(attributesEmail, firstName);
};
