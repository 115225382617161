import { AnalyticsInstance } from '@/common/service/analytics';
import { IPurchaseItem } from './domain';
import { IApiCartItem } from '@/entities/Cart/domain/Cart.domain';
import { ITERABLE_FORMAT_DATE, SERVER_FORMAT_DATE } from '../../constants/timeConstants';
import { format, parse } from 'date-fns';
import URL from '../urlsUtils';

export const convCartItemsToPurchaseItems = (cartItems: IApiCartItem[]) => {
    try {
        const items: IPurchaseItem[] = [];
        cartItems.forEach((item) => {
            Object.values(item.tickets).forEach((ticket) => {
                const date = parse(item.arrival_date, SERVER_FORMAT_DATE, new Date()).getTime();
                items.push({
                    id: parseInt(ticket.id, 10).toString(),
                    name: [item.product_name, ticket.name].filter(Boolean).join(' - '),
                    description: ticket.description,
                    price: parseFloat(ticket.price),
                    quantity: parseInt(ticket.booked, 10),
                    imageUrl: `${URL.image}/activity/${item.product_id}/960x720/${item.product_slug}.jpg`,
                    url: `https://www.tripshock.com/${item.product_slug}/details/${item.product_id}/`,
                    dataFields: {
                        product_id: parseInt(item.product_id, 10),
                        arrival_date: format(date, ITERABLE_FORMAT_DATE),
                        departure_date: format(date + 86400 * 7 * 1000, ITERABLE_FORMAT_DATE),
                        name: item.product_name,
                        variant: ticket.name,
                        brand: item.partner_title,
                        price_strike_out: ticket.strike_out ? parseFloat(ticket.strike_out) : null,
                        destination: {
                            ...item.product_city_info,
                            id: parseInt(item.product_city_info.id, 10),
                        },
                        category: {
                            ...item.product_category_info,
                            id: parseInt(item.product_category_info.id, 10),
                        },
                    },
                });
            });
        });

        return items;
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            console.error(err);
        }
        return [];
    }
};

export const createWishListObject = ({ id, slug }: { id: string | number; slug: string }) => ({
    product_id: typeof id === 'string' ? parseInt(id, 10) : id,
    originalPath: AnalyticsInstance.wpage,
    path: `/${slug}/details/${id}/`,
});
