import { addDays, addWeeks, format, startOfDay, startOfToday } from 'date-fns';

/* eslint-disable sonarjs/cognitive-complexity */
import { SERVER_FORMAT_DATE } from '@/common/app/constants/timeConstants';
import { mergeAvailability } from '@/common/app/utils/mergeAvailability';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import { IMeta } from '@/common/domain/Meta.domain';
import {
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';
import categoriesCache from 'scripts/cache/categories.json';
import destinationsListCache from 'scripts/cache/destinationsList.json';
import { Query, TSplitAvailAndSoldout, Today } from '../domain/Attractions.domain';
import { getAvailabilityShortByIds } from './ApiAttractionsPage';
import { VACATION_CATEGORIES } from '@/common/app/utils/categoriesUtils';
import { IActivityItem } from '@/common/service/api/Activity/Activity.domain';
import { loadCookiePromocode } from '@/common/service/storage/CookieStorage/CookieStorage';
import { isVacationCategory } from '@/common/app/utils/categoriesUtils';
import { TDate } from '@/common/app/contexts/DateContext';
import { getDateByTimezone } from '@/common/app/utils/dateUtils';

export const getDestination = (slug: string): IDestinationList | undefined => {
    const lowSlug = slug.toLowerCase();
    return destinationsListCache.find((v) => v.slug.toLowerCase() === lowSlug);
};

export const getCategory = (slug: string): IDestinationCategory | undefined => {
    const lowSlug = slug.toLowerCase();
    return categoriesCache.find((v) => v.slug.toLowerCase() === lowSlug);
};

export const createDateParams = (): Today => {
    const dayFrom = startOfDay(new Date());
    return {
        from: format(dayFrom, SERVER_FORMAT_DATE),
        to: format(addDays(dayFrom, 6), SERVER_FORMAT_DATE),
    };
};

export const createFaqSlug = (
    destination: IDestinationList,
    category?: IDestinationCategory
): string => {
    if (category) {
        return `attractions_${destination.slug}_${category.slug}`;
    }
    return `destination_${destination.state.code}_${destination.slug}`;
};

export const createAttractionsPageMeta = (
    destination: IDestinationList,
    category?: IDestinationCategory,
    totalTrips?: number
): IMeta => {
    const year = format(new Date(), 'yyyy');
    const month = format(new Date(), 'MMMM');
    const categoryName = category ? category.name : '';
    const destinationName = destination.name;

    const buildMetaContent = {
        category: {
            title: (number: number) =>
                `Top ${
                    number ? `${number} ` : ''
                }${destinationName} ${categoryName} ${year} - TripShock!`,
            description: (number: number) =>
                `Find ${
                    number ? `${number} or more of ` : ''
                }the best ${categoryName} in ${destinationName}, ${
                    destination.state.name
                } all in one place. Compare photos, rates, and availability and save up to 30% today!`,
            og_title: `${destinationName} ${categoryName}`,
        },
        hiddenCategory: {
            title: (number: number) =>
                `Top ${
                    number ? `${number} ` : ''
                }${destinationName} ${categoryName} Activities ${year}`,
            description: (number: number) =>
                `Find ${
                    number ? `${number} or more of ` : ''
                }the best ${categoryName} Activities in ${destinationName}, ${
                    destination.state.name
                } all in one place. Compare photos, rates, and availability and save up to 30% today!`,
            og_title: `${destinationName} ${categoryName} Activities`,
        },
        destination: {
            title: (number: number) =>
                `Top ${
                    number ? `${number} ` : ''
                }Attractions, Tours & Things To Do in ${destinationName}, ${
                    destination.state.code
                } ${year} - TripShock!`,
            description: (number: number) =>
                `Find ${
                    number ? `${number}+ ` : ''
                }top attractions & tours in ${destinationName}, ${
                    destination.state.name
                } with photos, rates & availability. Book the best things to do today, this weekend, or in ${month}.`,
            og_title: destinationName,
        },
    };
    const total = totalTrips ? totalTrips : destination.totalActivities;

    const gen = (() => {
        if (category) {
            if (isVacationCategory(category.slug)) {
                return buildMetaContent.hiddenCategory;
            }

            return buildMetaContent.category;
        }
        return buildMetaContent.destination;
    })();

    if (total >= 20) {
        return {
            title: gen.title(20),
            description: gen.description(20),
        };
    } else if (total >= 10 && total < 20) {
        return {
            title: gen.title(10),
            description: gen.description(10),
        };
    } else if (total >= 5) {
        return {
            title: gen.title(5),
            description: gen.description(5),
        };
    }

    return {
        title: gen.title(0),
        description: gen.description(0),
    };
};

export const createFirstTenActivities = async (
    fullList: IActivityItem[],
    dates: Today,
    count?: number
): Promise<IActivityWithAvailability[]> => {
    const sliceActivities = fullList.slice(0, count || 8);
    const activities_ids = sliceActivities.map((v) => v.id).join();

    const availability = await getAvailabilityShortByIds(activities_ids, dates);

    return mergeAvailability(sliceActivities, availability);
};

export const hideSoldoutFunction = (activity: IActivityWithAvailability): boolean => {
    if (!activity.days_availability) {
        return true;
    }
    for (const prop in activity.days_availability) {
        if (activity.days_availability[prop] === 1) {
            return true;
        }
    }
    return false;
};

export const CreateAttractionsPageTitle = (
    destination: IDestinationList,
    category?: IDestinationCategory
): string => {
    if (!destination) {
        return '';
    }
    if (!category) {
        return `Things to do in ${destination.name}, ${destination.state.code}`;
    }
    if (category && ['Air', 'Water', 'Land'].some((val) => val === category.name)) {
        return `${destination.name} ${category.name} Activities`;
    }
    if (category && VACATION_CATEGORIES.some((v) => v.slug === category.slug)) {
        return `${destination.name} ${category.name} Activities, Tours & Attractions`;
    }
    return `${destination.name} ${category.name}`;
};

export const CreateParams = (
    list: { id: string }[],
    query?: TDate,
    promocode?: string
): [string, Query & Today] => {
    const activity_ids = list?.map((v) => v.id).join();

    const currentDate = startOfToday();

    const queryDate = getDateByTimezone(new Date(query?.from || ''));

    const dayIsExpired = queryDate < currentDate;
    const from = dayIsExpired ? currentDate : queryDate;

    const maxDate = addWeeks(from, 2);
    const queryTo = query?.to ? startOfDay(getDateByTimezone(new Date(query.to))) : undefined;
    const to = queryTo && queryTo <= maxDate ? queryTo : addDays(from, 6);

    const promo = promocode || loadCookiePromocode();

    return [
        activity_ids,
        {
            from: format(from, SERVER_FORMAT_DATE),
            to: format(to, SERVER_FORMAT_DATE),
            promocode: promo,
        },
    ];
};

export const splitAvailAndSoldout = (list: IActivityWithAvailability[]): TSplitAvailAndSoldout => {
    return list.reduce(
        (result, activity) => {
            if (hideSoldoutFunction(activity)) {
                result.available.push(activity);
            } else {
                activity.price = 0;
                result.soldout.push(activity);
            }
            return result;
        },
        { available: [], soldout: [] } as TSplitAvailAndSoldout
    );
};

export const createAttractionsUrl = (destination?: string, category?: string): string => {
    if (category && destination) {
        return `/attractions/${destination}/${category.replace(/\//g, '-')}/`;
    }
    if (destination) {
        return `/attractions/${destination}/`;
    }
    return '/';
};
