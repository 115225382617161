import { iterableInstance } from '@/common/service/api/Axios';
import { LocalStorageTypes, getLS } from '@/common/service/storage';
import { TUser } from './domain';

enum ENDPOINTS {
    userUpdate = 'users/update',
    eventsTrack = 'events/track',
    commerceUpdateCart = 'commerce/updateCart',
    commerceTrackPurchase = 'commerce/trackPurchase',
    liveChat = 'liveChat',
}

const sendPostEvent = (path: string, data: object) => {
    return iterableInstance.post(path, data) as unknown as Promise<{
        status: number;
        data: object;
    }>;
};

export const getUser = () => {
    const user: TUser | undefined = getLS(LocalStorageTypes.LS_CONTACT_INFO);

    return user || ({} as TUser);
};

const res400Error = Promise.resolve({
    status: 400,
    data: { msg: 'Invalid email address' },
});

export const trackIterable = (eventName: string, dataFields: object, newEmail?: string) => {
    const { email } = getUser();

    if (email || newEmail) {
        return sendPostEvent(ENDPOINTS.eventsTrack, {
            email: newEmail || email,
            eventName,
            dataFields,
        });
    }
    return res400Error;
};

export const sendUserUpdate = ({ email, dataFields }: { email: string; dataFields: object }) => {
    if (Object.keys(dataFields).length !== 0) {
        return sendPostEvent(ENDPOINTS.userUpdate, {
            email,
            dataFields,
        });
    }
    return res400Error;
};

export const trackPurchaseIterable = (payload: object) => {
    const { email } = getUser();

    if (email) {
        return sendPostEvent(ENDPOINTS.commerceTrackPurchase, {
            user: { email },
            ...payload,
        });
    }
    return res400Error;
};

export const trackUpadteCartIterable = (items: object[]) => {
    const { email } = getUser();

    if (email) {
        return sendPostEvent(ENDPOINTS.commerceUpdateCart, {
            user: { email },
            items,
        });
    }
    return res400Error;
};

export const sendLiveChatIterable = (attributesEmail: string, firstName: string) => {
    const { email } = getUser();

    if (email !== attributesEmail) {
        trackIterable(ENDPOINTS.commerceUpdateCart, {}, attributesEmail);
        sendUserUpdate({ email: attributesEmail, dataFields: { firstName } });
    }
};
