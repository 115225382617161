interface VacationCategory {
    name: string;
    slug: string;
}

export const VACATION_CATEGORIES: VacationCategory[] = [
    { name: 'Cultural/Educational', slug: 'Cultural-Educational' },
    { name: 'Extreme', slug: 'Extreme' },
    { name: 'Food/Drinks Available', slug: 'Food-Drinks-Available' },
    { name: 'Group', slug: 'Group' },
    { name: 'Handicap Accessible', slug: 'Handicap-Accessible' },
    { name: 'Indoor/Rainy Day', slug: 'Indoor-Rainy-Day' },
    { name: 'Kid Friendly', slug: 'Kid-Friendly' },
    { name: 'Land', slug: 'Land' },
    { name: 'Luxury', slug: 'Luxury' },
    { name: 'Pet Friendly', slug: 'Pet-Friendly' },
    { name: 'Romantic', slug: 'Romantic' },
    { name: 'Senior Friendly', slug: 'Senior-Friendly' },
    { name: 'Sightseeing', slug: 'Sightseeing' },
    { name: 'Special Occasion', slug: 'Special-Occasion' },
    { name: 'Water', slug: 'Water' },
];

export const isVacationCategory = (categorySlug: string) => {
    return VACATION_CATEGORIES.some(
        (category) => category.slug.toLowerCase() === categorySlug.toLowerCase()
    );
};

export const getVacationCategories = () => VACATION_CATEGORIES;
